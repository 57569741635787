let ready = !document.currentScript; // pokud existuje document.currentscript, skript je synchronní a musí se počkat na domcontentloaded
let rq = []; // fronta initů ke zpracování po domcontentloaded
if (!ready) {window.addEventListener('DOMContentLoaded', ()=>rq.forEach(carousel.init)??(ready=true));}

// dostupné režimy; carousel=true coroselové chování, min=5 minimum potomků, aby správně fungoval carousel (doklonuje se, není-li)
const modes = {
    carousel : {min:5, carousel:true},
    fade : {},
};

const defaults = {
    width : '100%',
    randomize : false,
    interval : 2000,
    idle : 5000,
    mode : 'carousel',
    transition_time : 0.3,
    dots : true,
    arrows : true,
};

class carousel {

    constructor(element, par) {

        this.element = element;
        this.setup = Object.assign({}, defaults);

        // rozparsování a zpracování (validace) nastavení předaného parametrem //
        try {
            par?.split?.(';').map(str => str.replace(/\s/, '')).filter(str => str).forEach((str)=>{
                let [key, val] = str.split('=');
                switch(key) {
                    case 'width' :
                        this.setup[key] = val;
                        break;
                    case 'randomize' :
                    case 'no_idle' :
                    case 'dots' :
                    case 'arrows' :
                        this.setup[key] = !!parseInt(val);
                        break;
                    case 'interval' :
                    case 'idle' :
                        if (!isNaN(parseInt(val))) this.setup[key] = parseInt(val);
                        break;
                    case 'transition_time' :
                        if (!isNaN(parseFloat(val))) this.setup[key] = parseFloat(val);
                        break;
                    case 'mode' :
                        this.setup.mode = modes[val.toLowerCase()] ? val.toLowerCase() : 'carousel';
                }

            });
        } catch(e) {console.error('neplatný parametr');}

        if (ready) {carousel.init(this);}
        else rq.push(this);

    }

    static init(that) {

        that.element.classList.add('_ph_carousel');
        that.element.classList.add('_ph_carousel_'+that.setup.mode);
        that.element.style.setProperty('--item-width', that.setup.width);
        that.element.style.setProperty('--transition-time', that.setup.transition_time+'s');

        that.children = [...that.element.querySelectorAll('._ph_carousel>:not(script, link, style)')];

        if (!that.children.length) {return}

        that.dots = that.children.map(e=>document.createElement('div'));

        that.realCount=that.children.length;
        that.realHalf=Math.floor(that.realCount/2);

        that.actual = 0;

        if (that.setup.randomize) {
            that.children.sort(()=>Math.random()>.5?1:-1);
        }

        // potřebujeme NEJMÉNĚ pět viditelných elementů
        while(that.children.length < modes[that.setup.mode].min??0) {
            that.children = [...that.children, ...that.children.slice(0,that.realCount).map(e=>e.cloneNode({deep:true}))];
        }

        that.count = that.children.length;
        that.half=Math.floor(that.count/2);

        that.cssData = that.children.map(e=>e.dataset.css?.replace(/\s/, '_')??'');

        // obalení potomků kontajnerem navíc //
        that.children = that.children.map((e,i)=>{
            let c=document.createElement('div');
            c.appendChild(e);
            that.element.appendChild(c);
            return c;
        });

        // stvoření ovládátka //
        // ďubky
        if (that.setup.dots) {
            let dots = document.createElement('div');
            dots.className = "_c_dots";
            that.dots.forEach(dot => dots.appendChild(dot));
            that.element.appendChild(dots);
        }
        // šipky
        if (that.setup.arrows) {
            that.element.insertAdjacentHTML('beforeend', '<div class="_c_arrow_left"><span class="icon-arrow-left2"></span></div>');
            that.element.insertAdjacentHTML('beforeend', '<div class="_c_arrow_right"><span class="icon-arrow-right2"></span></div>');
        }

        that.element.addEventListener('click', e=>that.click(e), true);


        that.setPos(0);

    }

    static render(that) {
        that.children.forEach((e, i)=>{
            if (modes[that.setup.mode].carousel) {
                switch (i) {
                    case (that.actual - 2 + that.count) % that.count:
                        e.className = '_c_before_2';
                        break;
                    case (that.actual - 1 + that.count) % that.count:
                        e.className = '_c_before_1';
                        break;
                    case that.actual:
                        e.className = '_c_active';
                        break;
                    case (that.actual + 1) % that.count:
                        e.className = '_c_after_1';
                        break;
                    case (that.actual + 2) % that.count:
                        e.className = '_c_after_2';
                        break;
                    default :
                        e.className = '';
                }
            } else {
                e.className = that.actual === i ? '_c_active' : '';
            }
        });
        that.element.className = that.element.className.split(' ').filter(c=>!/^_c_active-/.test(c)).join(' ');
        if (that.cssData[that.actual]) that.element.classList.add('_c_active-'+that.cssData[that.actual]);
        try {
            that.element.dispatchEvent(new CustomEvent('carouselChange', {bubbles:true}));
        } catch(e) {}
        that.dots.forEach((dot, i)=>dot.className = i==that.target%that.realCount ? '_c_dot_active' : '');
    }

    static shl(that) {
        that.actual = (that.actual+1) % that.count;
        carousel.render(that);
    }

    static shr(that) {
        that.actual = (that.actual-1+that.count) % that.count;
        carousel.render(that);
    }

    setPos(pos=this.target) {
        clearTimeout(this.timeout);
        this.target = pos;

        if (modes[this.setup.mode].carousel) {

            if (this.actual != this.target) {
                let abs = this.target - this.actual;
                carousel [ // nalezení směru (nejkratší cesty) k cílovému obrázku s ohledem na přetočení kolem dokola :)
                    (Math.abs(abs) < this.half
                            ? abs < 0
                            : abs > 0
                    ) ? 'shr' : 'shl'
                    ](this);
            } else {
                carousel.render(this);
            }
        } else {
            this.actual = this.target;
            carousel.render(this);
        }


        // carousel a změna o více než jednu pozici //
        if (modes[this.setup.mode].carousel){
            if (this.actual != this.target) {
                setTimeout(this.setPos.bind(this), 100);
                this.element.style.setProperty('--transition-time', '.1s');
            } else {
                setTimeout(()=>this.element.style.setProperty('--transition-time', this.setup.transition_time + 's'), 100);
            }
        } else  if (this.setup.interval) {
            this.element.style.setProperty('--transition-time', this.setup.transition_time + 's');
            this.timeout = setTimeout(this.interval.bind(this), this.setup.interval);
        } else {
            this.element.style.setProperty('--transition-time', this.setup.transition_time + 's');
        }

    }

    interval() {
        // kurzor nad aktivním prvkem - neměníme obsah !!
        if (this.element.matches(':hover') && !this.setup.no_idle) {
            this.idle();
        } else {
            this.target=(this.target+1)%this.count;
            this.setPos();
        }
    }

    idle() {
        // čekání, až kurzor odtáhne, může se obnovit carousel
        clearTimeout(this.timeout);
        if (this.element.matches(':hover')) {
            this.t0 = performance.now();
            this.timeout=setTimeout(this.idle.bind(this), 250);
        } else if ((performance.now() - this.t0) >= (this.setup.idle || this.setup.interval)) {
            this.interval();
        } else {
            this.timeout=setTimeout(this.idle.bind(this), 250);
        }

    }

    afterClick() {
        // zastavení kolotoče při aktivitě uživatele (ruční posun)
        this.t0 = performance.now();
        clearTimeout(this.timeout);
        if (this.setup.interval && this.setup.idle && !this.setup.no_idle) {
            this.timeout=setTimeout(this.idle.bind(this), 250);
        } else if (this.setup.interval) {
            this.timeout=setTimeout(this.interval.bind(this), this.setup.interval);
        }
    }

    click(event) {
        // obsluha teček a šipek
        let e;
        if (e = event.target.closest('._c_dots>div')) {
            let i;
            if (~(i= this.dots.findIndex(d => d === e))) {
                // nalezení nejkratší cesty v případě, že se na začátku klonovalo //
                if (this.count > this.realCount) {
                    let abs = i - this.actual % this.realCount;

                    let target = (this.count + this.actual + abs)

                    if (Math.abs(abs) > this.realHalf) {
                        target += abs > 0 ? -this.realCount : this.realCount
                    }

                    this.setPos(target % this.count);
                } else {
                    this.setPos(i);
                }
                this.afterClick();
            }
        } else if (e=event.target.closest('._c_arrow_left')) {
            this.setPos((this.actual - 1 + this.count) % this.count);
            this.afterClick();
        } else if (e=event.target.closest('._c_arrow_right')) {
            this.setPos((this.actual + 1) % this.count);
            this.afterClick();
        }

    }


}

window.carousel=(element, par)=>new carousel(element, par);

